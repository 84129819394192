<template>

    <div style="width: 100%; height: 600px;">
      <div class="prism-player" id="J_prismPlayers"></div>
      <!-- <script type="text/template" id="endPreviewTemplate">
        <div class="vip_limit_content">
          <div class="vip_limit_wrap">
            <p class="title">视频试看结束</p>
            <div div class="vip_limit_close"><span class="vip_limit_close_btn">x</span></div>
          </div>
        </div>
      </script> -->
      
    </div>
</template>
<style type="text/css">
    .vip_limit_content {
        display: flex;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .vip_limit_content .title {
        font-size: 18px;
        line-height: 36px;
        color: #fff;
        text-align: center;
        width: 100%;
    }

    .vip_limit_close {
        text-align: center;
        margin-top: 20px;
    }

    .vip_limit_close span {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 36px;
        background: rgba(165, 165, 165, 0.54);
        border-radius: 50%;
        font-size: 24px;
        cursor: pointer;
    }
</style>
<script>
    export default {
        props: {
            videoId: {
                type: String,
                default: ""
            },
            kpointId: {
                type: String,
                default: ""
            },
            videoTime: {
                type: String,
                default: ""
            },
            kpointSource: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                player: null,
                videoTimeSelected:null,
                theTimer:null
            };
        },
        mounted() {
            this.videoTimeSelected = this.videoTime
            this.start();
        },
        beforeDestroy() {
            // 因previewtime是全局的.所以在试看播放器销毁前,要将试看时间制空
            // this.player.setPreviewTime(undefined);
            clearInterval(this.theTimer)
            this.theTimer = null
            if(this.player){
              this.player.dispose();
              this.player = null;
            }
            
        },
        methods: {
            start() {
              let that = this;
              console.log(that.kpointSource);
              //30外部课件，直接播放地址形式。
              if (that.kpointSource == "30") {
                that.player = new Aliplayer({
                  id: "J_prismPlayers",
                  width: "100%",
                  height: "100%",
                  autoplay: true,
                  format: "m3u8",
                  encryptType: "1",
                  isLive: false,
                  rePlay: false,
                  playsinline: true,
                  preload: true,
                  controlBarVisibility: "hover",
                  // components: [{
                  //   name: "PreviewVodComponent",
                  //   type: AliPlayerComponent.PreviewVodComponent,
                  //   args: [that.videoTimeSelected, "#endPreviewTemplate", ""]
                  // }],
                  useH5Prism: true,
                  //支持播放地址播放,此播放优先级最高
                  source: that.videoId,
                  //播放方式二：点播用户推荐
                  // vid: that.videoId,
                  // playauth: result1.data,
                  //cover: 'http://liveroom-img.oss-cn-qingdao.aliyuncs.com/logo.png',
                  skinLayout: [
                    {name: "bigPlayButton", align: "blabs", x: 30, y: 80},
                    {name: "H5Loading", align: "cc"},
                    {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
                    {name: "infoDisplay"},
                    {name: "tooltip", align: "blabs", x: 0, y: 56},
                    {name: "thumbnail"},
                    {
                      name: "controlBar",
                      align: "blabs",
                      x: 0,
                      y: 0,
                      children: [
                        {name: "progress", align: "blabs", x: 0, y: 44},
                        {name: "playButton", align: "tl", x: 15, y: 12},
                        {name: "timeDisplay", align: "tl", x: 10, y: 7},
                        {name: "fullScreenButton", align: "tr", x: 10, y: 12},
                        {name: "setting", align: "tr", x: 15, y: 12},
                        {name: "volume", align: "tr", x: 5, y: 10}
                      ]
                    }
                  ]
                }, function (player) {
                  that.countdownDom()
                });
              }else{
                //10,是平台课件，通过权限方式播放；20，21其他都是通过接口方式获取播放地址的课件。
                that.$post("/sys/video/videoPlayAuth", {videoId: that.videoId,kpointSource:that.kpointSource})
                  .then(result1 => {
                    if (result1.status === "0") {
                      if(that.kpointSource == "10") {
                        that.player = new Aliplayer({
                          id: "J_prismPlayers",
                          width: "100%",
                          height: "100%",
                          autoplay: true,
                          format: "m3u8",
                          encryptType: "1",
                          isLive: false,
                          rePlay: false,
                          playsinline: true,
                          preload: true,
                          controlBarVisibility: "hover",
                          // components: [{
                          //   name: "PreviewVodComponent",
                          //   type: AliPlayerComponent.PreviewVodComponent,
                          //   args: [that.videoTimeSelected, "#endPreviewTemplate", `09:59`]
                          // }],
                          useH5Prism: true,
                          //支持播放地址播放,此播放优先级最高
                          //source: "http://cloud.video.taobao.com/play/u/2554695624/p/1/e/6/t/1/fv/102/28552077.mp4",
                          //播放方式二：点播用户推荐
                          vid: that.videoId,
                          playauth: result1.data,
                          //cover: 'http://liveroom-img.oss-cn-qingdao.aliyuncs.com/logo.png',
                          skinLayout: [
                            {name: "bigPlayButton", align: "blabs", x: 30, y: 80},
                            {name: "H5Loading", align: "cc"},
                            {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
                            {name: "infoDisplay"},
                            {name: "tooltip", align: "blabs", x: 0, y: 56},
                            {name: "thumbnail"},
                            {
                              name: "controlBar",
                              align: "blabs",
                              x: 0,
                              y: 0,
                              children: [
                                {name: "progress", align: "blabs", x: 0, y: 44},
                                {name: "playButton", align: "tl", x: 15, y: 12},
                                {name: "timeDisplay", align: "tl", x: 10, y: 7},
                                {name: "fullScreenButton", align: "tr", x: 10, y: 12},
                                {name: "setting", align: "tr", x: 15, y: 12},
                                {name: "volume", align: "tr", x: 5, y: 10}
                              ]
                            }
                          ]
                        }, function (player) {
                          that.countdownDom()
                        });
                      } else {
                        that.player = new Aliplayer({
                          id: "J_prismPlayers",
                          width: "100%",
                          height: "100%",
                          autoplay: true,
                          format: "m3u8",
                          encryptType: "1",
                          isLive: false,
                          rePlay: false,
                          playsinline: true,
                          preload: true,
                          controlBarVisibility: "hover",
                          // components: [{
                          //   name: "PreviewVodComponent",
                          //   type: AliPlayerComponent.PreviewVodComponent,
                          //   args: [that.videoTimeSelected, "#endPreviewTemplate", ""]
                          // }],
                          useH5Prism: true,
                          //支持播放地址播放,此播放优先级最高
                          source: result1.data,
                          //播放方式二：点播用户推荐
                          // vid: that.videoId,
                          // playauth: result1.data,
                          //cover: 'http://liveroom-img.oss-cn-qingdao.aliyuncs.com/logo.png',
                          skinLayout: [
                            {name: "bigPlayButton", align: "blabs", x: 30, y: 80},
                            {name: "H5Loading", align: "cc"},
                            {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
                            {name: "infoDisplay"},
                            {name: "tooltip", align: "blabs", x: 0, y: 56},
                            {name: "thumbnail"},
                            {
                              name: "controlBar",
                              align: "blabs",
                              x: 0,
                              y: 0,
                              children: [
                                {name: "progress", align: "blabs", x: 0, y: 44},
                                {name: "playButton", align: "tl", x: 15, y: 12},
                                {name: "timeDisplay", align: "tl", x: 10, y: 7},
                                {name: "fullScreenButton", align: "tr", x: 10, y: 12},
                                {name: "setting", align: "tr", x: 15, y: 12},
                                {name: "volume", align: "tr", x: 5, y: 10}
                              ]
                            }
                          ]
                        }, function (player) {
                          that.countdownDom()
                        });
                      }
                    }
                  });
              }
            },
            dd() {
              if(this.player){
                this.player.dispose();
                this.player = null;
              }
            },
            countdownDom(){
              if(!this.videoTime) return
              const J_prismPlayers = document.getElementById("J_prismPlayers");
              const div=document.createElement("div");  
              div.classList.add('preview-vod-component')
              div.style.display="block"
              div.innerHTML=`
                <div class="preview-component-layer">
                  <div class="preview-custom"> <div class="vip_limit_content"> <div class="vip_limit_wrap"> <p class="title">视频试看结束</p> <div div="" class="vip_limit_close"></div> </div> </div> </div>
                </div>
                <div class="preview-component-tip">
                  <span class="can-preview"> 该视频可试看 </span><span class="preview-time"> ${this.secondTurnMinute(this.videoTime,true)}</span> <span class="preview-custom-bar">09:59</span>
                </div>
              `  
              J_prismPlayers.appendChild(div)
              const previewCustomBar = document.querySelector(".preview-custom-bar")
              this.theTimer = setInterval(()=>{
                this.videoTimeSelected--
                previewCustomBar.innerHTML = this.secondTurnMinute(this.videoTimeSelected)
                // console.log(this.videoTimeSelected);
                if(this.videoTimeSelected==0){
                  clearInterval(this.theTimer)
                  this.theTimer = null
                  document.querySelector(".preview-component-layer").style.display="block"
                  this.player.pause()
                }
              },1000)
            },
            // 秒转为分钟
            secondTurnMinute(time,flag=false){
              let data = this.$moment.duration(time, 'seconds')
              const {minutes,seconds} = data._data
              if(!flag){
                return `${minutes<10?'0'+minutes:minutes}:${seconds<10?'0'+seconds:seconds}`
              }else{
                if(seconds>0){
                  return `${minutes}分钟${seconds}秒`
                }else{
                  return `${minutes}分钟`
                }
              }
              
            }

        }
    };
</script>

<style lang="less" scoped></style>
