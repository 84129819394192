<template>
    <div style="width: 100%; height: 700px; position: relative" id="disv">
        <div class="prism-player" id="J_prismPlayer"></div>
    </div>
</template>

<script>
  import { createSwigger, distorySwigger } from "../utils/swigger";
  import { mapGetters } from "vuex";

  export default {
    props: {
      videoId: {
        type: String,
        default: ""
      },
      kpointId: {
        type: String,
        default: ""
      },
      courseId: {
        type: String,
        default: ""
      },
      fullname: {
        type: String,
        default: ""
      },
      kpointSource: {
        type: String,
        default: ""
      },
      kpointVideoUrl: {
        type: String,
        default: ""
      },
      watermark: {//是否开启水印
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        player: null,
        currentTimes: null,
        Times: 0,
        compName:''
      };
    },
    mounted() {
      this.start();
    },
    computed: {
      ...mapGetters({
        userJson: "getUser"
      })
    },
    beforeDestroy() {
      clearInterval(this.currentTimes); // 清除定时器
      this.currentTimes = null;
      this.Times = 0;
      this.cancelTheLimit()
    },
    created() {
      if(this.userJson.compId){
        this.getCompany(this.userJson.compId)
      }
      this.$nextTick(() => {
        // 禁用选择
        document.onselectstart = new Function("event.returnValue=false");
        //禁止f12
        document.οnkeydοwn = new Function("event.returnValue=false");
      });

      // 上面的禁止f12那段代码没有生效，但是加了下面这段就能生效。
      document.onkeydown = function(e) {
        if (e && e.keyCode === 123) {
          e.returnValue = false;
          // e.keyCode = 0   //去掉也可以的，倘若要写，则需要setter 以及 getter配合使用，不配合，会报错
          return false;
        }
      };
      document.oncontextmenu = function() {
        return false;
      };
    },
    methods: {
      // 取消禁用
      cancelTheLimit(){
        document.onselectstart = null
        document.οnkeydοwn = null
        document.onkeydown = null
        document.oncontextmenu = null
      },
      // 查询单位名称
      getCompany(id) {
        this.$post("/sys/company/id", { compId: id }).then((res) => {
          this.compName = res.data.compName;
        });
      },
      start() {
        let that = this;
        //30外部课件，直接播放地址形式。
        if (that.kpointSource == "30") {
          that.player = new Aliplayer(
            {
              id: "J_prismPlayer",
              width: "100%",
              height: "100%",
              autoplay: true,
              format: "m3u8",
              encryptType: "1",
              isLive: false,
              rePlay: false,
              playsinline: true,
              preload: true,
              controlBarVisibility: "hover",
              useH5Prism: true,
              //支持播放地址播放,此播放优先级最高
              source: that.videoId,
              // "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4",
              //播放方式二：点播用户推荐
              // vid: that.videoId,
              // playauth: result1.data,
              //cover: 'http://liveroom-img.oss-cn-qingdao.aliyuncs.com/logo.png',
              skinLayout: [
                { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                { name: "H5Loading", align: "cc" },
                { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                { name: "infoDisplay" },
                { name: "tooltip", align: "blabs", x: 0, y: 56 },
                { name: "thumbnail" },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    { name: "progress", align: "blabs", x: 0, y: 44 },
                    { name: "playButton", align: "tl", x: 15, y: 12 },
                    { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                    {
                      name: "fullScreenButton",
                      align: "tr",
                      x: 10,
                      y: 12
                    },
                    { name: "setting", align: "tr", x: 15, y: 12 },
                    { name: "volume", align: "tr", x: 5, y: 10 }
                  ]
                }
              ]
            },
            function(player) {
              that.previewEnd = false;
              // 准备播放
              player.on("ready", function() {
                let vid = document.querySelector("#J_prismPlayer video");
                vid.disablePictureInPicture = true;
                if(that.userJson.videoIsMark||that.userJson.videoIsMark===undefined){
                  createSwigger(that.compName, that.userJson.fullname);
                }else if(that.userJson.compId&&that.watermark){
                  createSwigger(that.compName, that.userJson.fullname);
                }
                
              });
              // 暂停
              player.on("pause", function() {
                clearInterval(that.currentTimes); // 清除定时器
                that.currentTimes = null;
              });
              // 恢复暂停
              player.on("play", function() {
                that.getCurrentTimes();
              });
              // 播放结束
              player.on("ended", function() {
                that.toPlaybackRecord();
                that.$emit("ended");
              });
            }
          );
        } else {
          //10,是平台课件，通过权限方式播放；20，21其他都是通过接口方式获取播放地址的课件。
          that.$post("/sys/video/videoPlayAuth", {
            videoId: that.videoId,
            kpointSource: that.kpointSource
          }).then((result1) => {
            if (result1.status === "0") {
              setTimeout(() => {
                if (that.kpointSource == "10" || !that.kpointSource) {
                  that.player = new Aliplayer({
                      id: "J_prismPlayer",
                      width: "100%",
                      height: "100%",
                      autoplay: true,
                      format: "m3u8",
                      encryptType: "1",
                      isLive: false,
                      rePlay: false,
                      playsinline: true,
                      preload: true,
                      controlBarVisibility: "hover",
                      useH5Prism: true,
                      //支持播放地址播放,此播放优先级最高
                      //source: "http://cloud.video.taobao.com/play/u/2554695624/p/1/e/6/t/1/fv/102/28552077.mp4",
                      //播放方式二：点播用户推荐
                      vid: that.videoId,
                      playauth: result1.data,
                      //cover: 'http://liveroom-img.oss-cn-qingdao.aliyuncs.com/logo.png',
                      skinLayout: [
                        { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                        { name: "H5Loading", align: "cc" },
                        { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                        { name: "infoDisplay" },
                        { name: "tooltip", align: "blabs", x: 0, y: 56 },
                        { name: "thumbnail" },
                        {
                          name: "controlBar",
                          align: "blabs",
                          x: 0,
                          y: 0,
                          children: [
                            { name: "progress", align: "blabs", x: 0, y: 44 },
                            { name: "playButton", align: "tl", x: 15, y: 12 },
                            { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                            {
                              name: "fullScreenButton",
                              align: "tr",
                              x: 10,
                              y: 12
                            },
                            { name: "setting", align: "tr", x: 15, y: 12 },
                            { name: "volume", align: "tr", x: 5, y: 10 }
                          ]
                        }
                      ]
                    },
                    function(player) {
                      that.previewEnd = false;
                      // 准备播放
                      player.on("ready", function() {
                        let vid = document.querySelector(
                          "#J_prismPlayer video"
                        );
                        vid.disablePictureInPicture = true;
                        if(that.userJson.videoIsMark||that.userJson.videoIsMark===undefined){
                          createSwigger(that.compName, that.userJson.fullname);
                        }else if(that.userJson.compId&&that.watermark){
                          createSwigger(that.compName, that.userJson.fullname);
                        }
                      });
                      // 暂停
                      player.on("pause", function() {
                        clearInterval(that.currentTimes); // 清除定时器
                        that.currentTimes = null;
                      });
                      // 恢复暂停
                      player.on("play", function() {
                        that.getCurrentTimes();
                      });
                      // 播放结束
                      player.on("ended", function() {
                        that.toPlaybackRecord();
                        that.$emit("ended");
                      });
                    }
                  );
                } else {
                  that.player = new Aliplayer({
                      id: "J_prismPlayer",
                      width: "100%",
                      height: "100%",
                      autoplay: true,
                      format: "m3u8",
                      encryptType: "1",
                      isLive: false,
                      rePlay: false,
                      playsinline: true,
                      preload: true,
                      controlBarVisibility: "hover",
                      useH5Prism: true,
                      //支持播放地址播放,此播放优先级最高
                      source: result1.data,
                      //播放方式二：点播用户推荐
                      // vid: that.videoId,
                      // playauth: result1.data,
                      //cover: 'http://liveroom-img.oss-cn-qingdao.aliyuncs.com/logo.png',
                      skinLayout: [
                        { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                        { name: "H5Loading", align: "cc" },
                        { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                        { name: "infoDisplay" },
                        { name: "tooltip", align: "blabs", x: 0, y: 56 },
                        { name: "thumbnail" },
                        {
                          name: "controlBar",
                          align: "blabs",
                          x: 0,
                          y: 0,
                          children: [
                            { name: "progress", align: "blabs", x: 0, y: 44 },
                            { name: "playButton", align: "tl", x: 15, y: 12 },
                            { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                            {
                              name: "fullScreenButton",
                              align: "tr",
                              x: 10,
                              y: 12
                            },
                            { name: "setting", align: "tr", x: 15, y: 12 },
                            { name: "volume", align: "tr", x: 5, y: 10 }
                          ]
                        }
                      ]
                    },
                    function(player) {
                      that.previewEnd = false;
                      // 准备播放
                      player.on("ready", function() {
                        let vid = document.querySelector(
                          "#J_prismPlayer video"
                        );
                        vid.disablePictureInPicture = true;
                        if(that.userJson.videoIsMark||that.userJson.videoIsMark===undefined){
                          createSwigger(that.compName, that.userJson.fullname);
                        }else if(that.userJson.compId&&that.watermark){
                          createSwigger(that.compName, that.userJson.fullname);
                        }
                      });
                      // 暂停
                      player.on("pause", function() {
                        clearInterval(that.currentTimes); // 清除定时器
                        that.currentTimes = null;
                      });
                      // 恢复暂停
                      player.on("play", function() {
                        that.getCurrentTimes();
                      });
                      // 播放结束
                      player.on("ended", function() {
                        that.toPlaybackRecord();
                        that.$emit("ended");
                      });
                    }
                  );
                }
              }, 500);
            }
          });
        }
      },
      // 关闭视频
      dd() {
        this.toPlaybackRecord();
        if(this.player){
          this.player.dispose();
          this.player = null;
        }

        distorySwigger();
      },
      // 开始播放 - 记录播放时长
      getCurrentTimes() {
        this.currentTimes = setInterval(() => {
          this.Times++;
        }, 1000);
      },
      // 发送播放记录
      toPlaybackRecord() {
        let data = {
          watchTime: this.Times
        };
        if (this.courseId) {
          data.courseId = this.courseId;
        }
        if (this.kpointId) {
          data.kpointId = this.kpointId;
        }
        if (this.courseId || this.kpointId) {
          this.$post("/biz/course/saveOrUpdateWatchLog", data).then((res) => {
            clearInterval(this.currentTimes); // 清除定时器
            this.currentTimes = null;
            this.Times = 0;
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
